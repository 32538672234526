// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

$ = window.jQuery = window.$ = require("jquery");

require("popper.js");
require("bootstrap");
require("bootstrap/dist/css/bootstrap.css");
require('bootstrap-select/dist/js/bootstrap-select');
require('bootstrap-select/dist/css/bootstrap-select.css');
require("jquery-validation");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery-validation/dist/localization/messages_ja.min");
require("jquery-ui-dist/jquery-ui.min");

// daterangepicker
window.moment = require('moment');
require('daterangepicker');
require('daterangepicker/daterangepicker.css');

// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "stylesheets/application";
